;(function ($, Drupal) {
  Drupal.behaviors.mobileMenu = {
    attach: function (context, settings) {
      var hamburger = $('.s-header__toggle');
      var container = $('.s-header');
      hamburger.click(function () {
        container.toggleClass('s-header--open-menu');
      });
    }
  };
})(jQuery, Drupal);