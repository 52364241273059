;(function ($, Drupal) {
  Drupal.behaviors.cartPage = {
    attach: function (context, settings) {
      if (screen.width < 640) {
        var $productHeader = $('.view-commerce-cart-form th.views-field-nothing');
        var $product = $('.view-commerce-cart-form td.views-field-nothing');
        $productHeader.remove();
        $product.each(function () {
          var $productParent = $(this).parent();
          console.log($productParent);
          $(this).attr("colspan", 4);
          var $productRow = $('<tr class="product"></tr>');
          $productRow.append($(this));
          $productRow.insertBefore($productParent);
        });
      }
    }
  };
})(jQuery, Drupal);